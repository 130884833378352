export const roleState =[
    {
        value:0,
        name: '使用'
    },{
        value:1,
        name: '停用'
    },{
        value:2,
        name: '删除'
    },
]
export const presetState =[
    {
        value:0,
        name: '是'
    },{
        value:1,
        name: '否'
    }
]
export const sexState = [
    {
        value:0,
        name: '男'
    },{
        value:1,
        name: '女'
    }
]
export const projectState =[
    {
        value:0,
        name: '正常'
    },{
        value:1,
        name: '停工'
    },{
        value:2,
        name: '完结'
    },
]
export const timeState = [
    {
        value:5,
        name: '5S'
    },{
        value:10,
        name: '10S'
    }
]
