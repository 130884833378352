<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="人员姓名">
          <el-input
              size="small"
              clearable
              v-model="searchForm.staffName"
              placeholder="请输入人员姓名">
          </el-input>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-cascader
              size="small"
              clearable
              v-model="orgaIdArr"
              :options="copanyListInfo"
              popper-class="custom-cascader"
              filterable
              :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    value:'orgaPathIds'
                  }"
              @change="cascaderChange">
          </el-cascader>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        noexport="true"
        title="人员信息"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="staffName"
          label="人员姓名"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="staffSex"
          label="人员性别"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.staffSex == 0">男</span>
          <span v-if="scope.row.staffSex == 1">女</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="orgaName"
          align="center"
          label="所属机构">
      </el-table-column>
      <el-table-column
          prop="staffPic"
          align="center"
          label="头像">
        <template slot-scope="scope">
          <div v-if="scope.row.staffPic">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.staffPic"
                fit="contain" @click="showImg(scope.row.staffPic)">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          align="center"
          label="创建时间">
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span class="el-icon-edit btn-icon" title="编辑" @click="editItem(scope.row)"></span>
          <span class="el-icon-delete btn-icon" title="删除" @click="delItem(scope.row)"></span>
          <span class="el-icon-refresh btn-icon" title="重置密码" @click="resetPss(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>
    <custom-dialog
        :visible.sync="showDialog"
        :title="dialogTitle"
        @close="colseDialog"
        @submit="editsubmit">
      <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules" ref="editStaffForm">
        <el-form-item label="用户照片" prop="staffPic">
          <el-upload
              class="avatar-uploader"
              :action="`${uploadUrl}File/upload`"
              :show-file-list="false"
              :on-change="uploadChange"
              :data={type:1}
              :on-success="uploadSuccess">
            <img
                v-if="editForm.staffPic"
                :src="editForm.staffPic"
                class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-row :gutter="160">
          <el-col :span="12">
            <el-form-item label="用户姓名" prop="staffName">
              <el-input
                  size="small"
                  v-model="editForm.staffName"
                  placeholder="请输入用户姓名">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="staffSex">
              <el-select
                  class="block-select"
                  size="small"
                  v-model="editForm.staffSex"
                  placeholder="请选择性别">
                <el-option
                    v-for="item in sexState"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                  size="small"
                  class="block-select"
                  v-model="editForm.orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                  }"
                  @change="cascaderEditChange">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="staffMobile">
              <el-input
                  size="small"
                  v-model="editForm.staffMobile"
                  placeholder="请输入手机号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="idCardNo">
              <el-input
                  size="small"
                  v-model="editForm.idCardNo"
                  placeholder="请输入证件号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="staffAccount">
              <el-input
                  size="small"
                  v-model="editForm.staffAccount"
                  placeholder="请输入账号">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import common from "../../../api/modules/common";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import CustomDialog from "../../../components/common/CustomDialog";
import basic from "../../../api/modules/basic";
import {organizationCascader} from "../../../utils/tool";
import config from "../../../api/config";
import {sexState} from "../../../config/dataStatus";

export default {
  name: "staff",
  components: {CustomDialog, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      editForm: {
        idCardNo: "",
        orgaId: "", //机构id，有企业选企业，有公司选公司
        projId: "",
        staffId: "",
        staffMobile: "",
        staffName: "",
        staffPic: "",
        staffSex: "",
        staffAccount: "",
        orgaIdArr:[]
      },
      rules: {
        staffName: [
          {required: true, message: "请输入人员姓名", trigger: "blur"},
        ],
        staffSex: [
          {required: true, message: "请选择性别", trigger: "change"},
        ],
        orgaIdArr: [
          {required: true, message: "请选择所属机构", trigger: "change"},
        ],
        staffMobile: [
          {required: true, message: "请输入手机号码", trigger: "blur"},
        ],
      },
      orgaIdArr: [],
      copanyListInfo: [],
      uploadUrl: config.uploadUrl,
      sexState: sexState,
      delArr: [] // 批量删除
    };
  },
  mounted() {
    this.getStaffInfo();
    this.getCopanyInfo();
  },
  //监听电话号码变化给账号赋初始值
  watch: {
    "editForm.staffMobile": {
      handler(newVal, oldVal) {
        if (!this.editForm.staffId) {
          this.editForm.staffAccount = newVal;
        }
      },
      deep: true,
    },
  },

  methods: {
    getCopanyInfo() {
      basic.getOrganizationTree({orgaPid: 0}).then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    getStaffInfo() {
      common.selectStaff(this.searchForm).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getStaffInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getStaffInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.orgaIdArr = [];
      this.getStaffInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getStaffInfo();
    },
    tableDel() {
      this.$confirm(`此操作将永久删除选中的管理人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        common.delStaff({staffId:this.delArr}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getStaffInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    tableAdd() {
      this.dialogTitle = "新增管理人员";
      this.editForm = {
        idCardNo: "",
        orgaId: "", //机构id，有企业选企业，有公司选公司
        projId: "",
        staffId: "",
        staffMobile: "",
        staffName: "",
        staffPic: "",
        staffSex: "",
        staffAccount: "",
        orgaIdArr:[]
      }
      // 产品要求：自动带出当前登录用户所属项目。
      let orgaPathIds = this.$store.state.common.user.orgaPathIds
      if(orgaPathIds && orgaPathIds.match('-')){
        this.editForm.orgaIdArr = orgaPathIds.split('-').map(item => Number(item))
        let len = this.editForm.orgaIdArr.length
        this.editForm.orgaId = this.editForm.orgaIdArr[len-1]
      }else if(orgaPathIds) {
        this.editForm.orgaIdArr = [Number(orgaPathIds)]
        this.editForm.orgaId = this.editForm.orgaIdArr[0]
      }
      this.showDialog = true;
      console.log('this.editForm',this.editForm)
      this.resetForm()
    },
    selectionChange(val) {
      this.delArr = []
      console.log(val, 'val');
      val.map(item => {
        this.delArr.push(item.staffId)
      })
      console.log(this.delArr, 'delArr');
    },
    editItem(item) {
      this.editForm = item;
      if (item.orgaPathIds && item.orgaPathIds.match('-')) {
        // this.editForm.orgaIdArr = item.orgaPathIds.split('-').map(item => Number(item))
        let orgaIdArr = item.orgaPathIds.split('-').map(item => Number(item))
        let orgaLevel  = this.$store.state.common.user.orgaLevel
        if(orgaLevel == 0){
          this.editForm.orgaIdArr = orgaIdArr
        }else if(orgaLevel == 1){
          let a = orgaIdArr.shift()
          this.editForm.orgaIdArr = orgaIdArr
        }else {
          this.editForm.orgaIdArr = [orgaIdArr[2]]
        }
      }else if(item.orgaPathIds){
        this.editForm.orgaIdArr = [Number(item.orgaPathIds)]
      }
      this.dialogTitle = "编辑管理人员";
      this.showDialog = true;
      this.resetForm()
    },
    delItem(item) {
      console.log([item.staffId]);
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除该管理人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        common.delStaff({staffId:[item.staffId]}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getStaffInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    resetPss(item){
      this.$confirm(`此操作将重置该用户密码, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        common.resetPwd({staffId:item.staffId}).then((res) => {
          this.$message({
            message: "重置成功",
            type: "success",
          });
          this.getStaffInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    editsubmit() {
      console.log(this.editForm)
      this.$refs.editStaffForm.validate((valid) => {
        if (valid) {
          if (this.editForm.staffId) {
            // 编辑
            common.updateStaff(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.showDialog = false;
              this.getStaffInfo();
            });
          } else {
            // 新增
            common.newStaff(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.showDialog = false;
              this.getStaffInfo();
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colseDialog() {
      // 关闭编辑
      this.showDialog = false;
    },
    cascaderChange(val) {
      let valLen = val.length;
      this.searchForm.orgaPathIds = val[valLen - 1];
    },
    cascaderEditChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    uploadChange(file) {
      console.log(file,'file');
      // common.fileUpload()
    },
    uploadSuccess(val) {
      console.log(val,'val');
      if (val.code == 200) {
        this.editForm.staffPic = val.message;
      }
    },
    resetForm() {
      if(this.$refs.editStaffForm){
        this.$refs.editStaffForm.clearValidate();
        this.$refs.editStaffForm.resetFields();
      }
    },
    showImg(url){
      let html = `<div class="img-alert"><img src="${url}"/></div>`
      this.$alert(html, '图片', {
        dangerouslyUseHTMLString: true
      });

    }
  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding-right: 40px;
}
</style>